/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type CustomIntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ContentImportSource from '../content-service/content-import-source';
import { SyncBehaviorType } from '../import-service/import-source';
import { EntityType } from '../data/entity-types';
import { task } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type {
  SourceParams,
  SourceStatusParams,
} from 'embercom/components/content-service/ai-content-library/sources/add-source-modal';

export type SyncSourceType =
  | 'notion'
  | 'guru'
  | 'confluence'
  | 'zendesk'
  | 'external_content'
  | 'salesforce_knowledge'
  | 'box'
  | 'document360';

export type SyncErrorType =
  | 'sync_error'
  | 'import_error'
  | 'unauthorized'
  | 'non_retryable_error'
  | 'retryable_error'
  | 'no_content'
  | 'deactivated_source';

export enum SyncStatus {
  LIVE = 'live',
  SYNCING = 'syncing',
  SYNCED = 'synced',
  ERROR = 'error',
}

export enum SyncBehavior {
  MANUAL_SYNC = 'manual_sync',
  IMPORT = 'import',
  AUTO_SYNC = 'auto_sync',
  API = 'api',
}

enum SyncAction {
  RESYNC = 'resync',
  REMOVE = 'remove',
  IMPORT = 'import',
  ADD_NEW = 'add_new',
}

const ALLOWED_ACTION_BY_TYPE: Partial<{
  [key in EntityType]: SyncAction[];
}> = {
  [EntityType.ArticleContent]: [SyncAction.RESYNC, SyncAction.REMOVE, SyncAction.ADD_NEW],
  [EntityType.ExternalContent]: [SyncAction.RESYNC, SyncAction.REMOVE],
  [EntityType.InternalArticle]: [
    SyncAction.RESYNC,
    SyncAction.IMPORT,
    SyncAction.REMOVE,
    SyncAction.ADD_NEW,
  ],
};

const ERROR_TYPE_TRANSLATION_KEYS: Record<SyncErrorType, string> = {
  sync_error: 'knowledge-hub.overview.sections.syncs.rows.errors.sync_error',
  import_error: 'knowledge-hub.overview.sections.syncs.rows.errors.import_error',
  unauthorized: 'knowledge-hub.overview.sections.syncs.rows.errors.unauthorized',
  non_retryable_error: 'knowledge-hub.overview.sections.syncs.rows.errors.non_retryable_error',
  retryable_error: 'knowledge-hub.overview.sections.syncs.rows.errors.retryable_error',
  no_content: 'knowledge-hub.overview.sections.syncs.rows.errors.no_content',
  deactivated_source: 'knowledge-hub.overview.sections.syncs.rows.errors.deactivated_source',
};

export default class SyncSourceWrapper extends Model {
  @service appService: any;
  @service declare store: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: any;
  @service declare intl: CustomIntlService;

  @attr('number') declare entityType: EntityType;
  @attr('string') declare sourceType: SyncSourceType;
  @attr('string') declare lastSyncedAt: string;
  @attr('string') declare status: SyncStatus;
  @attr('number') declare sourceId: number;
  @attr('string') declare title: string;
  @attr('string') declare siteUrl: string | null;
  @attr('number') declare folderId?: number;
  @attr('string') declare errorType?: SyncErrorType;
  @attr('string') declare syncBehavior?: SyncBehavior;

  private canPerform(action: SyncAction) {
    return ALLOWED_ACTION_BY_TYPE[this.entityType]?.includes(action);
  }

  get canResync() {
    return this.canPerform(SyncAction.RESYNC);
  }

  get canImport() {
    return this.canPerform(SyncAction.IMPORT);
  }

  get canRemove() {
    return this.canPerform(SyncAction.REMOVE);
  }

  get canAddNew() {
    return this.canPerform(SyncAction.ADD_NEW);
  }

  get isLive() {
    return this.status === SyncStatus.LIVE;
  }

  @task
  *resync(): TaskGenerator<void> {
    try {
      yield this.knowledgeHubService.ensurePermissions();
    } catch (error) {
      return;
    }

    if (!this.canResync) {
      return;
    }

    try {
      yield this.resyncForType();
      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-start'),
        `${this.id}`,
      );
      yield this.knowledgeHubService.onSummaryChange();
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyWarning(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-already-running'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-error'),
      );
    }
  }

  async import() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (error) {
      return;
    }

    if (!this.canImport) {
      return;
    }

    try {
      await this.importForType();
      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.import-source-processing'),
        `${this.id}`,
      );
      this.knowledgeHubService.onSummaryChange();
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyWarning(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-already-running'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.import-source-error'),
      );
    }
  }

  async show() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (error) {
      return;
    }

    if (!this.canResync) {
      return;
    }

    try {
      let response = await this.showWebsite();
      this.knowledgeHubService.onSummaryChange();
      return response;
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.source-settings-error'),
      );
      return undefined;
    }
  }

  async update(params: SourceParams | SourceStatusParams) {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (error) {
      return;
    }

    if (!this.canResync) {
      return;
    }

    let response = await this.updateWebsite(params);
    this.knowledgeHubService.onSummaryChange();
    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      this.intl.t('knowledge-hub.filterable-list.sources.actions.source-saved'),
      `${this.id}`,
    );
    return response;
  }

  private async importForType() {
    if (
      this.sourceType === 'notion' ||
      this.sourceType === 'guru' ||
      this.sourceType === 'confluence' ||
      this.sourceType === 'salesforce_knowledge' ||
      this.sourceType === 'box'
    ) {
      return this.importInternalArticleManualImportSource();
    }
  }

  async remove() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (error) {
      return;
    }

    try {
      await this.removeForType();
      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-done'),
        `${this.id}`,
      );
      this.knowledgeHubService.onSummaryChange();
    } catch (error) {
      let errorMessage =
        error.jqXHR.status === 409
          ? this.intl.t('knowledge-hub.filterable-list.sources.actions.delete-conflict-error')
          : this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-error');
      this.notificationsService.notifyError(errorMessage);
    }
    this.deleteRecord();
    this.unloadRecord();
  }

  async resyncForType() {
    if (this.sourceType === 'zendesk') {
      return this.resyncZendesk();
    } else if (this.sourceType === 'external_content') {
      return this.resyncWebsite();
    } else if (
      this.sourceType === 'notion' ||
      this.sourceType === 'guru' ||
      this.sourceType === 'confluence' ||
      this.sourceType === 'salesforce_knowledge' ||
      this.sourceType === 'box' ||
      this.sourceType === 'document360'
    ) {
      return this.resyncInternalArticleImportSource();
    }
  }

  private async removeForType() {
    if (this.sourceType === 'zendesk') {
      return this.removeZendesk();
    } else if (this.sourceType === 'external_content') {
      return this.removeWebsite();
    } else if (
      this.sourceType === 'notion' ||
      this.sourceType === 'guru' ||
      this.sourceType === 'confluence' ||
      this.sourceType === 'salesforce_knowledge' ||
      this.sourceType === 'box' ||
      this.sourceType === 'document360'
    ) {
      return this.removeInternalArticleImportSource();
    }
  }

  // Internal Articles

  private async resyncInternalArticleImportSource() {
    await post('/ember/import_service/import_sources/start_import', {
      app_id: this.appService.app.id,
      import_source_id: this.sourceId,
      sync_behavior: SyncBehaviorType.Sync,
    });
  }

  private async importInternalArticleManualImportSource() {
    return await post('/ember/import_service/import_sources/import', {
      app_id: this.appService.app.id,
      import_source_id: this.sourceId,
    });
  }

  private async removeInternalArticleImportSource() {
    let importSource = this.store.peekRecord('import-service/import-source', this.sourceId);
    await importSource.destroyRecord();
  }

  // Websites

  get peekWebsiteSource(): ContentImportSource | undefined {
    if (this.sourceType !== 'external_content') {
      return;
    }
    return this.store.peekRecord(
      'content-service/content-import-source',
      this.sourceId,
    ) as ContentImportSource;
  }

  private async resyncWebsite() {
    await post('/ember/content_service/content_import_sources/resync', {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      id: this.sourceId,
    });
  }

  private async removeWebsite() {
    let contentImportSource = this.peekWebsiteSource;
    await contentImportSource?.destroyRecord();
  }

  private async showWebsite(): Promise<ContentImportSource> {
    let contentImportSource = this.peekWebsiteSource;
    return contentImportSource!;
  }

  private async updateWebsite(params: SourceParams | SourceStatusParams) {
    try {
      let contentImportSource = this.peekWebsiteSource;
      if (contentImportSource === undefined) {
        throw new Error('Content import source not found');
      }

      if ('status' in params) {
        contentImportSource.status = params.status;
      }
      if ('additional_urls' in params) {
        contentImportSource.additionalUrls = params.additional_urls;
      }
      if ('use_sitemap' in params) {
        contentImportSource.useSitemap = params.use_sitemap;
      }
      if ('exclude_url_globs' in params) {
        contentImportSource.excludeUrlGlobs = params.exclude_url_globs;
      }
      if ('click_element_css_selector' in params) {
        contentImportSource.clickCssSelector = params.click_element_css_selector;
      }
      if ('wait_for_selector' in params) {
        contentImportSource.waitForSelector = params.wait_for_selector;
      }
      if ('remove_element_css_selectors' in params) {
        contentImportSource.removeCssSelectors = params.remove_element_css_selectors;
      }
      if ('actor_data' in params && params.actor_data) {
        contentImportSource.setActorData(params.actor_data);
      }

      await contentImportSource.save();
      return contentImportSource;
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.source-save-error'),
      );
      throw error;
    }
  }

  // Public articles

  private async resyncZendesk() {
    await post(`/ember/article_sync_settings/${this.sourceId}/trigger_sync`, {
      app_id: this.appService.app.id,
    });
  }

  private async removeZendesk() {
    await post(`/ember/article_sync_settings/${this.sourceId}/pause`, {
      app_id: this.appService.app.id,
    });
  }

  get hasError() {
    return this.status === SyncStatus.ERROR;
  }

  get isSyncing() {
    return this.status === SyncStatus.SYNCING || taskFor(this.resync).isRunning;
  }

  get isImport() {
    return this.syncBehavior === SyncBehavior.IMPORT;
  }

  get errorMessage(): string | undefined {
    if (!this.hasError) {
      return;
    }

    if (!this.errorType) {
      return this.intl.t('knowledge-hub.overview.sections.syncs.rows.errors.fallback_error');
    }

    let translationKey =
      ERROR_TYPE_TRANSLATION_KEYS[this.errorType] ||
      'knowledge-hub.overview.sections.syncs.rows.errors.fallback_error';
    return this.intl.t(translationKey);
  }
}
